import { template as template_fb31b8aa59b24dacaa6b2b7dda09d837 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fb31b8aa59b24dacaa6b2b7dda09d837(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
