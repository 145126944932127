import { template as template_bc5d4e28d11e4207890818558af69b5e } from "@ember/template-compiler";
const WelcomeHeader = template_bc5d4e28d11e4207890818558af69b5e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
