import { template as template_aee11e513b3e43cc85f62e6253d3c75d } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_aee11e513b3e43cc85f62e6253d3c75d(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
