import { template as template_6930bb0c5eb441a7a5a7853d43fbb29a } from "@ember/template-compiler";
const SidebarSectionMessage = template_6930bb0c5eb441a7a5a7853d43fbb29a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
