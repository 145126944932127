import { template as template_3f4a3619cf3c4e40999e69b31e8369c1 } from "@ember/template-compiler";
const FKLabel = template_3f4a3619cf3c4e40999e69b31e8369c1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
